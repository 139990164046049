import * as React from "react"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"
import cx from "classnames"

import Section from "@components/elements/section"

import styles from "./newsFeatures.module.scss"

interface NewsFeaturesProps {
  title: string;
  features: Array<{
    linkText: string;
    linkUrl: string;
    logo: any;
  }>
}

const NewsFeatures = ({ features, title }: NewsFeaturesProps) => (
  <Section className={styles.NewsFeatures} containerClassName={styles.container}>
    <div className={styles.title}>{title}</div>
    <div className={styles.features}>
      {
        features.map((feature, index) => {
          const logo = getImage(feature.logo)
          return (
            <div className={styles.feature} key={index}>
              <div className={styles.logo}>
                {
                  !feature.logo.childImageSharp && (<img style={{}} src={feature.logo.publicURL} />)
                }
                {
                  feature.logo.childImageSharp && (<GatsbyImage image={logo} objectFit="contain" alt="" />)
                }
              </div>
              <div className={styles.content}>
                <a href={feature.linkUrl} target='_blank' rel='noopener noreferrer'>{feature.linkText}</a>
              </div>
            </div>
          )
        })
      }
    </div>
  </Section>
)

export default NewsFeatures
