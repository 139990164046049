import * as React from "react"
import { graphql } from "gatsby"

import Layout from "@components/layout/layout"
import NewsFeatures from "@components/newsFeatures"

const News = ({ data }: any) => {
  const {
    title,
    featuresData
  } = data.pageData

  return (
    <Layout title="News" description="">
      <NewsFeatures features={featuresData} title={title} />
    </Layout>
  )
}

export default News

export const pageQuery = graphql`
  query {
    pageData: newsJson {
      title
      featuresData: features {
        linkText
        linkUrl
        logo: logoUrl {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              width: 150
            )
          }
          extension
          publicURL
        }
      }
    }
  }
`
